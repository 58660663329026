import React from "react";
import footerBanner from "images/footer/compra-en-linea-2024-empleados.jpg";

export default function StoreDealers() {
	return (
		<div id="dealers-view">
			<div className="dealers-stripe">
				<h3>Continental Tire de México</h3>
			</div>
			<div>
				<img src={footerBanner} style={{width: "100%"}} alt="Compra en Línea y recoge con distribuidores autorizados" />
			</div>
		</div>
	);
}
